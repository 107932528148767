<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="4">
        <v-card class="fill-height">
          <v-card-title class="primary--text font-weight-bold">
            <div>
              <v-icon>mdi-cogs</v-icon>
              {{ $t('messages.views.admin.configuration.maintenance.services') }}
            </div>
            <v-spacer />
            <div>
              <v-btn
                icon
                @click="refreshServices"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon
                    color="primary"
                    size="30"
                  >
                    mdi-application-outline
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary--text">
                    web-app
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <ul>
                      <li>
                        <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.version') }}</span>
                        <span> : {{ appVersion }}</span>
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-action>
                  <v-icon
                    color="primary"
                    size="30"
                  >
                    mdi-router-network
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary--text">
                    wamp-server
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    v-if="connectionFailure"
                    color="error"
                    size="30"
                  >
                    mdi-close
                  </v-icon>
                  <v-progress-circular
                    v-else-if="session === null"
                    indeterminate
                    color="blue"
                    size="30"
                    :width="2"
                    op
                  />
                  <v-icon
                    v-else
                    color="success"
                    size="30"
                  >
                    mdi-check
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider />

              <template v-for="(service, name) in services">
                <v-list-item
                  :key="`service-${name}`"
                >
                  <v-list-item-action>
                    <v-icon
                      color="primary"
                      size="30"
                    >
                      {{ icons[name] }}
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold secondary--text">
                      {{ name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <ul>
                        <li v-if="service.version">
                          <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.version') }}</span>
                          <span> : {{ service.version }}</span>
                        </li>
                        <li
                          v-if="service.latency !== null"
                          :class="service.latency > 500 ? 'font-weight-bold error--text' : ''"
                        >
                          <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.latency') }}</span>
                          <span> : {{ service.latency + ' ms' }}</span>
                        </li>
                      </ul>
                    </v-list-item-subtitle>

                    <div
                      v-if="service.error"
                      class="mt-2 error--text"
                    >
                      <div class="font-weight-bold">
                        {{ $t('messages.views.admin.configuration.maintenance.error') }}
                      </div>
                      <div>
                        {{ service.error }}
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon
                      v-if="service.alive === true"
                      color="success"
                      size="30"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-else-if="service.alive === false"
                      color="error"
                      size="30"
                    >
                      mdi-close
                    </v-icon>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="blue"
                      size="30"
                      :width="2"
                      op
                    />
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="name !== Object.keys(services)[Object.keys(services).length - 1]"
                  :key="`service-divider-${name}`"
                />
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card class="fill-height d-flex flex-column">
          <v-card-title class="primary--text font-weight-bold">
            <div>
              <v-icon>mdi-traffic-light</v-icon>
              {{ $t('messages.views.admin.configuration.maintenance.stations') }}
            </div>
            <v-spacer />
            <div>
              <v-btn
                icon
                @click="refreshStationWatchers"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text class="flex-grow-1">
            <o-loader v-if="loading.stationWatchers" />
            <v-list v-else-if="stationWatchers && stationWatchers.length">
              <template v-for="(stationWatcher, i) in stationWatchers">
                <v-list-item
                  :key="`station-watcher-${i}`"
                >
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="cli.stationWatcherName = stationWatcher.name"
                    >
                      <v-icon
                        size="30"
                      >
                        mdi-chevron-right-box
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold secondary--text">
                      {{ stationWatcher.name }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="() => showStationWatcherDialog(stationWatcher)"
                    >
                      <v-icon
                        v-if="stationWatcher.alive === true && stationWatcher.diaser && stationWatcher.diaser.latency !== false"
                        color="success"
                        size="30"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon
                        v-else
                        color="error"
                        size="30"
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="i !== Object.keys(stationWatchers)[Object.keys(stationWatchers).length - 1]"
                  :key="`station-watcher-divider-${i}`"
                />
              </template>
            </v-list>
            <span v-else>
              {{ $t('messages.views.admin.configuration.maintenance.noStationWatcher') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="stationWatcherDialog.show"
      max-width="1000"
    >
      <v-card v-if="stationWatcherDialog.stationWatcher !== null">
        <v-card-title>
          <span class="primary--text">
            {{ stationWatcherDialog.stationWatcher.name }}
          </span>

          <v-spacer />

          <span>
            <v-btn
              icon
              @click="stationWatcherDialog.show = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <span class="h6">
                <v-icon
                  v-if="stationWatcherDialog.stationWatcher.alive === true"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  color="error"
                >
                  mdi-close
                </v-icon>

                {{ $t('messages.views.admin.configuration.maintenance.stationWatcher') }}
              </span>
              <ul>
                <li v-if="stationWatcherDialog.stationWatcher.version">
                  <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.version') }}</span>
                  <span> : {{ stationWatcherDialog.stationWatcher.version }}</span>
                </li>
                <li
                  v-if="stationWatcherDialog.stationWatcher.latency !== null"
                  :class="stationWatcherDialog.stationWatcher.latency > 500 ? 'font-weight-bold error--text' : ''"
                >
                  <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.latency') }}</span>
                  <span> : {{ stationWatcherDialog.stationWatcher.latency + ' ms' }}</span>
                </li>
              </ul>
            </v-col>
            <v-col>
              <span class="h6">
                <v-icon
                  v-if="stationWatcherDialog.stationWatcher && stationWatcherDialog.stationWatcher.diaser && stationWatcherDialog.stationWatcher.diaser.latency !== false"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  color="error"
                >
                  mdi-close
                </v-icon>

                {{ $t('messages.views.admin.configuration.maintenance.station') }}
              </span>
              <ul>
                <li v-if="stationWatcherDialog.stationWatcher.diaser && stationWatcherDialog.stationWatcher.diaser.latency !== false">
                  <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.latency') }}</span>
                  <span> : {{ stationWatcherDialog.stationWatcher.diaser.latency + ' ms' }}</span>
                </li>
                <li v-if="stationWatcherDialog.stationWatcher.diaser && stationWatcherDialog.stationWatcher.diaser.address !== false">
                  <span class="font-weight-bold">{{ $t('messages.views.admin.configuration.maintenance.address') }}</span>
                  <span> : {{ stationWatcherDialog.stationWatcher.diaser.address }}</span>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <command-line-interface
      v-if="cli.stationWatcherName !== null"
      :station-watcher-name="cli.stationWatcherName"
      @close="cli.stationWatcherName = null"
    />
  </v-container>
</template>

<script>
  import { services } from '@/plugins/apollo';
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      CommandLineInterface: () => import('@/views/admin/components/configuration/maintenance/CommandLineInterface'),
    },

    data: function () {
      return {
        cli: {
          stationWatcherName: null,
        },
        services: {},
        stationWatchers: [],
        timers: {},
        loading: {
          stationWatchers: true,
        },
        icons: {
          'api-gateway': 'mdi-import',
          customer: 'mdi-cog',
          'floating-traffic-data': 'mdi-cloud',
          stats: 'mdi-chart-bar',
          stations: 'mdi-traffic-light',
          information: 'mdi-information',
          smartway: 'mdi-chip',
          'activity-thread': 'mdi-bell',
        },
        stationWatcherDialog: {
          show: false,
          stationWatcher: null,
        },
      };
    },

    computed: {
      ...mapState('wampClient', ['session', 'connectionFailure']),
      ...mapGetters('app', ['appVersion']),
    },

    mounted () {
      this.$store.commit('wampClient/connect');
      this.refreshServices().then(() => {
        this.refreshStationWatchers();
      });
    },

    methods: {
      getServiceDefaultAttributes () {
        return {
          latency: null,
          alive: null,
          version: null,
          error: null,
        };
      },

      refreshStationWatchers () {
        this.loading.stationWatchers = true;
        this.getStationWatchersHealth().then((stationWatchers) => {
          this.stationWatchers = stationWatchers;
        }).finally(() => {
          this.loading.stationWatchers = false;
        });
      },

      refreshServices () {
        for (const service of services) {
          Vue.set(this.services, service.name, this.getServiceDefaultAttributes());
        }

        return this.refreshApiGatewayHealth().then(() => {
          this.refreshSubServices();
        });
      },

      refreshApiGatewayHealth () {
        this.startTimer('api-gateway-health');

        return this.getApiGatewayHealth({}).then((data) => {
          this.services['api-gateway'].alive = true;
          this.services['api-gateway'].latency = this.stopTimer('api-gateway-health');
          this.services['api-gateway'].version = data.version;
        }).catch((e) => {
          this.services['api-gateway'].alive = false;
          this.services['api-gateway'].latency = null;
          this.services['api-gateway'].version = null;
          this.services['api-gateway'].error = e;
        });
      },

      refreshSubServices () {
        const subServices = {};
        for (const service of services) {
          if (service.version) {
            subServices[service.name] = service.version;
          }
        }

        return this.getApiGatewayHealth(subServices).then((data) => {
          for (const name in subServices) {
            const service = data.services[name];
            this.services[name].alive = service.alive;
            this.services[name].latency = service.latency;
            this.services[name].error = service.error;
            this.services[name].version = services.find((s) => s.name === name).version;
          }
        }).catch((e) => {
          this.services['api-gateway'].alive = false;
          this.services['api-gateway'].latency = null;
          this.services['api-gateway'].version = null;
          this.services['api-gateway'].error = e;
        });
      },

      getApiGatewayHealth (services) {
        return this.$apollo.query({
          query: require('@gql/views/admin/maintenance/health.gql'),
          client: 'api-gateway',
          variables: {
            services: services,
          },
          fetchPolicy: 'no-cache',
        })
          .then((result) => {
            return result.data.health;
          })
        ;
      },

      getStationWatchersHealth () {
        return this.$apollo.query({
          query: require('@gql/views/admin/maintenance/stationWatchersHealth.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
        })
          .then((result) => {
            const stationWatchers = result.data.health.stationWatchers;
            for (const stationWatcherName in result.data.health.stationWatchers) {
              stationWatchers[stationWatcherName].name = stationWatcherName;
            }

            return Object.values(stationWatchers);
          })
        ;
      },

      startTimer (name) {
        this.timers[name] = new Date().getTime();
      },

      stopTimer (name) {
        const timer = new Date().getTime() - this.timers[name];
        this.timers[name] = null;

        return timer;
      },

      showStationWatcherDialog (stationWatcher) {
        this.stationWatcherDialog.stationWatcher = stationWatcher;
        this.stationWatcherDialog.show = true;
      },
    },
  };
</script>
